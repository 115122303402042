<template>
  <div :class="rs_sn?'index-box':''">
    <div style="background-color: #fff" class="layout-box">
      <NavBar v-if="!rs_sn" :bool="true" @navCallback="navCallback" class="nav-bar"></NavBar>
      <div class="success-info" :class="!rs_sn?'border-b':''">
        <div class="success-prompt">
          <img src="~@/assets/order/ok@2x.png" class="success-img" />
          <span class="pay-ok">支付成功</span>
          <span class="actual-money">实付金额￥{{ twoNum(payMoney) }}</span>
        </div>
      </div>
      <div class="operation" >
        <div class="operation-text" @click="goToHome">返回首页</div>
        <div class="operation-text text-red" @click="goToOrderInfo">
          查看订单
        </div>
      </div>
<!--      <div class="operation" v-else>-->
<!--        <div class="operation-text text-red" @click="closeUrl" v-if="false">-->
<!--          确认-->
<!--        </div>-->
<!--      </div>-->
    </div>
    <div class="recommend" >
      <div class="title">-为你推荐-</div>
      <div>
        <GoodsShowLabel :tagId="0" :sendType="3"></GoodsShowLabel>
      </div>
    </div>

    <van-popup v-model="showPopup">
      <div class="red-packet">
        <div class="img-box">
          <img src="~@/assets/pay-ok/red-packet.png" class="red-packet-img" />
          <div class="red-packet-button" @click="goToShop">
            <span>立即前往</span>
            <!--            <div class="time-back-color">{{ timeNum }}</div>-->
          </div>
          <img
            src="~@/assets/pay-ok/close.png"
            class="red-packet-close"
            @click="onClose"
          />
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import NavBar from "@/layout/nav-bar/nav-bar";
import GoodsShowLabel from "@/components/goods-show/goods-show-label";
export default {
  name: "pay-ok",
  components: {
    GoodsShowLabel,
    NavBar,
  },
  data() {
    return {
      showPopup: false,
      timeNum: 5,
      isFixed: true,
      list: [],
      error: false,
      loading: false,
      timeServe: null,
      rs_sn:this.$route.query.rs_sn,
      orderId:
        this.$route.query.orderId === undefined
          ? "0"
          : this.$route.query.orderId,
      orderType:
        this.$route.query.orderType === undefined
          ? "1"
          : this.$route.query.orderType,
      payMoney:
        this.$route.query.payMoney === undefined
          ? "0"
          : this.$route.query.payMoney,
      shopId: this.$route.query.shop_id || 0,

    };
  },
  mounted() {
    if(!this.rs_sn){
      this.showPopup = true
    }else{
      this.showPopup = false
    }


    // let _that = this;
    // this.timeServe = setInterval(function () {
    //   --_that.timeNum;
    //   if (_that.timeNum <= 0) {
    //     _that.timeNum = 1;
    //     clearInterval(_that.timeServe);
    //     if (_that.shopId * 1 <= 0) {
    //       _that.$router.replace({ name: "home" });
    //     } else {
    //       _that.$router.replace({
    //         name: "beans-shopping",
    //         query: { shopId: _that.shopId },
    //       });
    //     }
    //   }
    // }, 1000);
  },
  methods: {
    closeUrl(){
      window.close()
    },
    onClose() {
      clearInterval(this.timeServe);
      this.showPopup = false;
    },
    goToShop() {
      this.$router.push({
        name: "beans-shopping",
        query: { shopId: this.shopId },
      });
    },
    twoNum(num) {
      // 获取两位小数
      return (num * 1).toFixed(2);
    },
    goToOrderInfo() {
      //跳转订单详情
      if (this.timeServe) {
        clearInterval(this.timeServe);
      }
      this.$router.push({
        name: "scan-code",
        query: { orderType: 3, orderId: this.orderId },
      });
    },
    goToHome() {
      if (this.timeServe) {
        clearInterval(this.timeServe);
      }
      this.$router.push({ name: "home" });
    },
    navCallback() {
      this.$router.push({
        path: "/goods",
        query: {
          goodsId: localStorage.getItem("GOODS_ID"),
        },
      });
      localStorage.removeItem("GOODS_ID");
    },
  },
};
</script>

<style lang="less" scoped>
  .index-box{
    width: 100%;
    height: 100vh;
    background-color: #fff;
  }
.nav-bar {
  background-color: #fff;
  border-bottom: 1px solid rgb(246, 246, 246);
}
.success-info {
  background-color: #fff;
  padding: 70px 10px 10px 10px;
}
.border-b{
  border-bottom: 1px dashed #c6c6c6;
}
.success-prompt {
  display: flex;
  flex-direction: column;
  align-items: center;
  .success-img {
    width: 52px;
  }
  .pay-ok {
    font-size: 20px;
    font-weight: 600;
    margin: 10px 0;
  }
  .actual-money {
    font-size: 20px;
    color: #6f6f6f;
  }
}
.operation {
  display: flex;
  justify-content: space-around;
  padding: 20px 80px;
  background-color: #fff;
  .operation-text {
    width: 102px;
    height: 39px;
    border: 1px solid #6f6f6f;
    opacity: 1;
    border-radius: 19px;
    text-align: center;
    line-height: 39px;
    font-size: 16px;
    &:active {
      background-color: rgb(235, 235, 235);
    }
  }
  .text-red {
    color: #ed301d;
    border: 1px solid #ed301d;
  }
}
.recommend {
  padding-top: 12px;
  .title {
    width: 100%;
    font-size: 16px;
    text-align: center;
  }
}
.van-popup {
  background-color: transparent;
  overflow: visible;
}
.red-packet {
  .img-box {
    position: relative;
    .red-packet-img {
      width: 283px;
      height: 363px;
    }
    .red-packet-button {
      position: absolute;
      bottom: 42px;
      left: 50%;
      transform: translateX(-50%);
      width: 125px;
      height: 42px;
      background: linear-gradient(0deg, #ffc853, #ffe8a0);
      box-shadow: 4px 6px 12px 0px rgba(154, 0, 19, 0.33);
      border-radius: 23px;
      text-align: center;
      line-height: 44px;
      font-size: 23px;
      color: #fa3e4a;
      .time-back-color {
        position: absolute;
        right: 6px;
        top: 50%;
        transform: translateY(-50%);
        width: 38px;
        height: 37px;
        background-color: #f12722;
        border: 1px solid #f3b530;
        color: #fde14b;
        text-align: center;
        line-height: 37px;
        box-shadow: 0px -3px 32px 0px rgba(241, 39, 34, 0.2);
        border-radius: 50%;
      }
    }
    .red-packet-close {
      position: absolute;
      display: block;
      bottom: -71px;
      left: 50%;
      transform: translateX(-50%);
      width: 43px;
      height: 43px;
    }
  }
}
</style>
